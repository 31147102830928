<template>
    <el-dialog
        append-to-body
        :close-on-click-modal="false"
        custom-class="center-dialog"
        :visible.sync="visible"
        width="50%">
        <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="first" class="base-from">
                <el-form ref="baseFrom" :model="baseFrom" label-width="120px" :rules="usRules">
                    <el-form-item label="主企业名称" prop="tenantName">
                        <el-input v-model="baseFrom.tenantName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="主企业身份" prop="isManager">
                        <el-input v-model="baseFrom.isManager" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="realName">
                        <el-input v-model="baseFrom.realName"></el-input>
                    </el-form-item>
                    <el-form-item label="账号(手机号)" prop="mobile">
                        <el-input v-model="baseFrom.mobile" disabled></el-input>
                    </el-form-item>
                </el-form>
                <div class="other-form">
                    <div class="v-logo">
                        <div class="upload-box">
                            <el-upload
                                class="avatar-uploader"
                                :show-file-list="false"
                                accept=".png,.jpg,.jpeg,.svg"
                                action="#"
                                :http-request="handleFileUpload"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="baseFrom.headPortrait && baseFrom.headPortrait !='default'" :src="baseFrom.headPortrait" class="avatar-image">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <span>用户Logo</span>
                        </div>
                        <!-- <div class="upload-box">
                            <el-upload
                                class="avatar-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <span>上传组织logo</span>
                        </div> -->
                    </div>
                    <!-- <div class="other-login">
                        <p>绑定钉钉、微信、企业微信登录更便捷</p>
                        <img
                            class="other-image"
                            src="@/assets/index/dingding.png"
                            alt=""
                            />
                        <img
                            class="other-image wechat"
                            src="@/assets/index/wechat.png"
                            alt=""
                            />
                    </div> -->
                </div>
            </el-tab-pane>
            <el-tab-pane label="密码修改" name="second">
                <el-form :model="ruleForm" ref="ruleForm" :rules="pwRules" label-width="120px" class="pw-form">
                    <el-form-item label="旧密码" prop="oldPwd">
                        <el-input type="password" v-model="ruleForm.oldPwd" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPwd">
                        <el-input type="password" v-model="ruleForm.newPwd" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="pass">
                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="centerSubmit">保 存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {
  editSelfApi,
  getInfo,
  editPwApi,
  changePw,
  filterUpload
} from "@/api/platformCenter";
export default {
  components:{
  },
  data() {
    return {
        visible: false,
        activeName: "first",
        baseFrom: {
            mobile: "",
            realName: "",
            tenantName: "",
            isManager: "",
            headPortrait: ""
        },
        ruleForm: {
            oldPwd: "",
            newPwd: "",
            pass: ""
        },
        imageUrl: "",
        usRules: {
          tenantName: [
            { required: true, message: '请输入主企业名称', trigger: 'blur' }
          ],
          isManager: [
            { required: true, message: '请输入主企业身份', trigger: 'blur' }
          ],
          realName: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入账号(手机号)', trigger: 'blur' }
          ]
        },
        pwRules: {
          pass: [
            { required: true, message: '请输入确认密码', trigger: 'blur' }
          ],
          newPwd: [
            { required: true, message: '请输入新密码', trigger: 'blur' }
          ],
          oldPwd: [
            { required: true, message: '请输入旧密码', trigger: 'blur' }
          ]
        }
    }
  },
  methods: {
    init() {
        this.visible = true
        getInfo().then(({ res }) => {
            this.$store.commit("setUserInfo", { ...res.data });
            sessionStorage.setItem("userInfo", JSON.stringify({...res.data, id: res.data.userId}));
            this.baseFrom.headPortrait = res.data.avatarUrl
            this.baseFrom.mobile = res.data.mobile
            this.baseFrom.realName = res.data.realName
            this.baseFrom.tenantName = res.data.tenantName
            this.baseFrom.isManager = res.data.isManager == "0" ? "普通用户" : "管理员"
        })
    },
    async handleFileUpload(data) {
      filterUpload({
        file: data.file,
      }).then(({ res }) => {
        this.baseFrom.headPortrait = window.location.origin + res.data.url;
      });
    },
    beforeAvatarUpload() {

    },
    handleAvatarSuccess() {

    },
    centerSubmit() {
        if(this.activeName == "first") {
            this.$refs.baseFrom.validate((valid) => {
                if(valid) {
                    editSelfApi({
                        realName: this.baseFrom.realName,
                        headPortrait: this.baseFrom.headPortrait,
                    }).then(res=> {
                        this.$message.success("保存成功")
                        this.visible = false
                        getInfo().then(({ res }) => {
                            this.$store.commit("setUserInfo", { ...res.data });
                            sessionStorage.setItem("userInfo", JSON.stringify({...res.data, id: res.data.userId}));
                        });
                    })
                }
            })
        } else {
            this.$refs.ruleForm.validate((valid) => {
                if (this.ruleForm.newPwd != this.ruleForm.pass) {
                    this.$message.error("新密码与确认密码不一致!");
                    return
                }
                if (valid) {
                    changePw({
                        // newPwd: this.ruleForm.newPwd,
                        // oldPwd: this.ruleForm.oldPwd,
                        // headPortrait: this.baseFrom.headPortrait,
                        oldPassword: this.ruleForm.oldPwd ? common.Encrypt(this.ruleForm.oldPwd) : "" ,
                        password: this.ruleForm.newPwd ? common.Encrypt(this.ruleForm.newPwd) : "",
                    }).then(res=> {
                        if(res.res.data) {
                            this.$message.success("密码修改成功!");
                        }
                        this.visible = false
                    })
                }
            });
        }
    }
  }
}
</script>

<style lang="scss" scoped>
    .base-from {
        display: flex;
        .el-form {
            width: 60%;
        }
        .other-form {
            padding: 0 4%;
            flex: 1;
            .upload-box {
                text-align: center;
                .avatar-image {
                    width: 68px;
                    height: 68px;
                    object-fit: contain;
                }
            }
            .v-logo {
                display: flex;
                justify-content: space-between;
            }
        }
        .other-login {
            margin-top: 20px;
            text-align: center;
            p {
                margin-bottom: 12px;
            }
            .other-image {
                padding: 0 10px;
                width: 60px;
            }
        }
    }
</style>
<style lang="scss">
    .base-from {
        .el-upload {
            position: relative;
            overflow: hidden;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
        }
        .avatar-uploader-icon {
            width: 68px;
            height: 68px;
            line-height: 68px;
        }
    }
</style>