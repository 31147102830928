import axios from "axios";
import vue from "@/main.js";
import { Message } from "element-ui";
import store from "@/store";
import router from "@/router";
const service = axios.create({
  baseURL: "/iiot/admin/server/common",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

service.interceptors.request.use(
  (config) => {
    let { data = {}, method, url, headers } = config;
    let params = JSON.parse(JSON.stringify(data));
    headers.Authorization =
    localStorage.getItem("ucenter_Authorization")  || "";
    headers.clientId =   localStorage.getItem("ucenter_client_id") || "";
    headers.parentEngineeringId = '1594574670182330370';
    // console.log("ice.interceptors.requ", config)
    if (params && params.requstType) {
      config.data = params;
      if(params.requstType == '1') {
        config.baseURL = "/iiot/admin/usercenter";
      } else if(params.requstType == '2') {
        config.baseURL = "/appshop/server";
      } else if(params.requstType == '3') {
        config.baseURL = "/it/api";
      } else if(params.requstType == '4') {
        config.baseURL = "/appbuild";
      } else if(params.requstType == '5') {
        headers['clientId'] = store.state.clientId || ""
        headers['appCode'] = store.state.appCode || ""
        headers.loginToken =
        localStorage.getItem("loginToken") || "";
        config.baseURL = "/";
      } 
    } else {
      let pageQuery = {};
      data.page && ((pageQuery.page = data.page), delete params.page);
      data.rows && ((pageQuery.rows = data.rows), delete params.rows);

      config.data = { params };
      Object.keys(pageQuery).length > 0 && (config.data.pageQuery = pageQuery);
    }
    if (config.url.includes("upload")) { 
      config.headers = {
        "Content-Type": "multipart/form-data;charset=utf-8",
      }
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("requstType", "4");
      config.data = formData
      config.baseURL = "/iiot/api";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  (response) => {
    if (response.data.code != 0 && response.data.code != 200) {
      if (response.data.code == 80001 || response.data.code == 401) {
        vue.$bus.$emit("logOut");
        const parent = window.parent;
        parent.postMessage({
          type: "logOut"
        }, '*')
      }
      console.log("工业互联网===logout", response)
      return Promise.reject(response);
    }
    return response.data;
  },
  (error) => Promise.reject(error.response)
);

let hasWait = false;

export default async (
  config,
  { showOk = false, showErr = true, lazypost = false } = {}
) => {
  if (config.method === "post" && lazypost) {
    if (!hasWait) {
      hasWait = true;
      setTimeout(() => {
        hasWait = false;
      }, 300);
    } else {
      return Promise.reject("请勿重复请求");
    }
  }

  const res = await service(config).catch((error) => {
    const response = error.data;

    showErr && Message.error(response.message || response.msg);
    return Promise.reject(error);
  });
  if (showOk && config.method === "post") {
    Message.success(res.message || res.msg);
  }

  return {
    res,
    resdata:
      res.data != null
        ? (res.data.page && res.data.page.rows) ||
          res.data.page ||
          res.data ||
          res
        : res,
    resData:
      res.data != null
        ? (res.data.page && res.data.page.rows) ||
          res.data.page ||
          res.data ||
          res
        : res,
  };
};
