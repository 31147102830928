import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let isMobile;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
  isMobile = true;
} else {
  isMobile = false;
}

const routes = [{
  path: '/',
  name: 'index',
  component: () => isMobile ? import('@/views/mobile/index') : import('@/views/pc/index')
},
{
  path: '/otPage',
  name: 'otPage',
  component: () => isMobile ? import('@/views/mobile/otPage') : import('@/views/pc/otPage')
},
{
  path: '/itPage',
  name: 'itPage',
  component: () => isMobile ? import('@/views/mobile/itPage') : import('@/views/pc/itPage')
},
{
  path: '/schemes',
  name: 'schemes',
  component: () => isMobile ? import('@/views/mobile/schemes') : import('@/views/pc/schemes')
},
{
  path: '/digitalTwins',
  name: 'digitalTwins',
  component: () => isMobile ? import('@/views/mobile/digitalTwins') : import('@/views/pc/digitalTwins')
},
{
  path: '/bigData',
  name: 'bigData',
  component: () => isMobile ? import('@/views/mobile/bigData') : import('@/views/pc/bigData')
}, {
  path: '/updateDate',
  name: 'updateDate',
  component: () => isMobile ? import('@/views/mobile/updateDate') : import('@/views/pc/updateDate')
}, {
  path: '/helpCenter',
  name: 'helpCenter',
  component: () => isMobile ? import('@/views/mobile/helpCenter') : import('@/views/pc/helpCenter')
},
{
  path: '/login',
  name: 'login',
  component: () => isMobile ? import('@/views/mobile/login') : import('@/views/pc/login')
},
{
  path: '/iiot/login',
  redirect: '/login', // 嵌套重定向
  name: "iiot-login",
  component: () => isMobile ? import('@/views/mobile/login') : import('@/views/pc/login')
},
{
  path: '/iiot/',
  redirect: '/', // 嵌套重定向
  name: "iiot-index",
  component: () => isMobile ? import('@/views/mobile/login') : import('@/views/pc/login')
},
{
  path: '/applicationCenter',
  name: 'applicationCenter',
  component: () => isMobile ? import('@/views/mobile/applicationCenter') : import('@/views/pc/applicationCenterNew')
},
{
  path: '/iiot/applicationCenter',
  redirect: '/applicationCenter', // 嵌套重定向
  name: "iiot-applicationCenter",
  component: () => isMobile ? import('@/views/mobile/applicationCenter') : import('@/views/pc/applicationCenterNew')
},
{
  path: '/appDetails',
  name: 'appDetails',
  component: () => isMobile ? import('@/views/mobile/appDetails') : import('@/views/pc/appDetailsNew')
},
{
  path: '/applicationStation',
  name: 'applicationStation',
  component: () => isMobile ? import('@/views/mobile/applicationStation') : import('@/views/pc/applicationStation')
},
{
  path: '/iiot/applicationStation',
  redirect: '/applicationStation', // 嵌套重定向
  name: "iiot-applicationStation",
  component: () => isMobile ? import('@/views/mobile/login') : import('@/views/pc/login')
},
{
  path: '/system',
  name: 'system',
  component: () => isMobile ? import('@/views/mobile/system') : import('@/views/pc/system')
},
{
  path: '/applicationStationDetail',
  name: 'applicationStationDetail',
  component: () => isMobile ? import('@/views/mobile/applicationStationDetail') : import('@/views/pc/applicationStationDetail')
},
{
  path: '/componentCenter',
  name: 'componentCenter',
  component: () => import('@/views/pc/componentCenter')
},
{
  path: '/about',
  name: 'about',
  component: () => isMobile ? import('@/views/mobile/about') : import('@/views/pc/about')
},
{
  path: '/componentCenterDetails',
  name: 'componentCenterDetails',
  component: () => import('@/views/pc/componentCenterCom/details')
},
{
  path: '/configApplication',
  name: 'configApplication',
  component: () => import('@/views/pc/configApplication/login')
},

]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router