<template>
  <div v-if="!isPCheadshow" class="header" @mouseleave="mouseleave">
    <div class="left" :class="scrollTop > 10 ? 'scroll-bg' : 'default-bg'">
      <img src="@/assets/logo@2x.png" class="imgsize" />

      <div class="center">
        <div class="tabDiv">
          <div
            class="item"
            :class="[tabIndex == tab.key ? 'acitve' : '']"
            @mouseenter="mouseenter(tab)"
            @click="clickItem(tab, index)"
            v-for="(tab, index) in tabsData"
            :key="tab.key"
          >
            {{ tab.value }}
          </div>
        </div>
      </div>

      <div class="right">
        <el-dropdown
          @command="onCommand"
          placement="bottom"
          class="role-wrapper"
          v-if="userDeptName"
          trigger="click"
        >
          <div class="text-wrapper">
            <img
              class="userIcon"
              src="@/assets/index/company_icon.png"
              alt=""
            />
            <div class="name">
              {{
                userDeptName === "undefined"
                  ? userInfo.tenantName
                  : userDeptName
              }}
            </div>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="role-item-wrapper">
            <div class="drop-top-wrapper">
              <el-dropdown-item
                :command="item"
                v-for="(item, index) in userDeptList"
                :key="index"
              >
                <div class="role-item">
                  <div class="item-left">
                    <img
                      class="icon-left"
                      v-if="!item.logo"
                      src="@/assets/index/company_icon.png"
                      alt=""
                    />
                    <img
                      class="icon-left"
                      v-if="item.logo"
                      :src="item.logo"
                      alt=""
                    />
                    <div class="role-name">
                      {{ item.clientName }}
                      <!-- <div class="person-tip" v-if="item.type == 1">个人版</div> -->
                    </div>
                  </div>
                  <!-- <div
                    class="item-right"
                    @click="onUpdate(item)"
                    v-if="item.isDevelop == 0"
                  >
                    <img class="icon" src="@/assets/index/upgrade.png" alt="" />
                    <div class="right-text">升级开发者</div>
                  </div> -->
                </div>
              </el-dropdown-item>
            </div>

            <el-dropdown-item command="add">
              <div class="role-item-add">
                <img class="add-image" src="@/assets/index/add.png" alt="" />
                <span class="text">创建企业/团队/个人</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="btn" @click="toLogin" v-if="!userDeptName">登录/注册</div>
        <el-dropdown placement="top-end" trigger="hover" v-if="userDeptName">
          <div class="btn" v-show="isDevelop == '1'">工作台</div>
          <div class="btn" v-show="isDevelop == '0'" @click="backstage('0')">
            应用平台
          </div>
          <el-dropdown-menu slot="dropdown" class="work-wrapper">
            <el-dropdown-item>
              <div
                class="title"
                @click="backstage('1')"
                v-if="isDevelop == '1'"
              >
                开发平台
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="text" v-if="isDevelop == '1'" @click="backstage('0')">
                应用平台
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="user">
          <!-- <el-popover
            placement="bottom"
            width="80"
            title="用户名称"
            trigger="hover"
          >
            <div>
              <div class="text" @click="exit">退出系统</div>
            </div>
            <el-button slot="reference">用户信息</el-button>
            <div slot="reference">
              <img class="imgSize" src="@/assets/common/user@2x.png" alt="" />
              <i class="el-icon-caret-bottom"></i>
            </div>
          </el-popover> -->
          <el-dropdown trigger="hover" v-if="userDeptName">
            <div class="el-dropdown-link">
              <img
                class="imgSize"
                v-if="!userInfo.avatarUrl || userInfo.avatarUrl == 'default'"
                src="@/assets/common/user@2x.png"
                alt=""
              />
              <img
                class="imgSize"
                v-else="userInfo.avatarUrl"
                :src="userInfo.avatarUrl"
                alt=""
              />
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>
                <div class="title">{{ userInfo.realName }}</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="title" @click="centerTo">个人中心</div>
              </el-dropdown-item>
              <!-- <el-dropdown-item v-if="showOpera">
                <div class="title" @click="linktTo">系统设置</div>
              </el-dropdown-item> -->
              <el-dropdown-item divided>
                <div class="text" @click="exit">退出系统</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- <div class="hoverContent" v-if="hoverTab == 1">
      <img src="@/assets/index/leftBg.png" class="imgsize" />
      <div class="hover-right">
        <div>
          <template v-for="(item, index) in developmentPlatform">
            <div
              v-if="index < 2"
              class="item"
              @click="goUrl(item.develop_code)"
              :key="item.id"
            >
              <div class="title">{{ item.develop_name }}开发平台</div>
              <div class="text">
                {{ item.develop_comments }}
              </div>
            </div>
          </template>
        </div>
        <div>
          <template v-for="(item, index) in developmentPlatform">
            <div
              class="item"
              v-if="index > 1"
              @click="goUrl(item.develop_code)"
              :key="item.id"
            >
              <div class="title">{{ item.develop_name }}开发平台</div>
              <div class="text">
                {{ item.develop_comments }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div> -->
    <div class="hoverContent" v-if="hoverTab == 1">
      <!-- <img src="@/assets/index/leftBg.png" class="imgsize" /> -->
      <div class="hover-right flex-wrap">
        <div class="item_use" v-for="item in appData" :key="item.id">
          <div class="title">{{ item.categoryName }}</div>
          <div
            class="text"
            v-for="item2 in item.apps"
            :key="item2.app_name"
            @click="goAppDetails(item2)"
          >
            {{ item2.appname }}
          </div>
        </div>
        <!-- <div class="item_use">
          <div class="title">供应链</div>
          <div class="text">供应商管理系统</div>
          <div class="text">仓储管理系统</div>
        </div>
        <div class="item_use">
          <div class="title">生产制造</div>
          <div class="text">供应商管理系统</div>
          <div class="text">设备云管系统</div>
        </div>
        <div class="item_use">
          <div class="title">运营服务</div>
          <div class="text">园区管理系统</div>
        </div>
        <div class="item_use">
          <div class="title">BI数据</div>
          <div class="text">BI报表助手</div>
        </div> -->
      </div>
    </div>
    <us-center ref="usCenter"></us-center>
  </div>
</template>

<script>
import { getPlatformList } from "@/api/platformCenter";
import {
  listWithApps,
  getUserDept,
  upgrade,
  sendHeartbeat,
  getInfo,
  getAuthInfo,
} from "@/api/platformCenter";
import { mapState } from "vuex";
import usCenter from "../pc/center";
export default {
  components: {
    usCenter,
  },
  data() {
    return {
      clientId: "",
      userDeptList: [],
      scrollTop: 0,
      hoverTab: -1,
      tabIndex: 0,
      tabsData: [
        {
          key: 0,
          value: "首页",
          url: "/",
        },
        // {
        //   key: 1,
        //   value: "开发平台",
        //   url: "/itPage",
        // },
        {
          key: 1,
          value: "应用市场",
          url: "/applicationCenter",
        },
        // {
        //   key: 3,
        //   value: "组件中心",
        //   url: "/componentCenter",
        // },
        {
          key: 2,
          value: "方案&案例",
          url: "/schemes",
        },
        {
          key: 3,
          value: "产品动态",
          url: "/updateDate",
        },
        {
          key: 4,
          value: "帮助支持",
          url: "/helpCenter",
        },
        {
          key: 5,
          value: "关于我们",
          url: "/about",
        },
      ],
      developmentPlatform: [], //开发平台
      appData: [], //应用市场
    };
  },
  computed: {
    ...mapState(["userInfo", "userDeptName", "authMain"]),
    isPCheadshow() {
      let isMobile;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
      ) {
        isMobile = true;
      } else {
        isMobile = false;
      }

      return (
        (this.$route.name == "system" && !isMobile) ||
        this.$route.name == "configApplication"
      );
    },
    isDevelop() {
      return sessionStorage.getItem("ucenter_develop") || "1";
    },
    showOpera() {
      return (
        this.authMain.find((af) => af && af.resCode == "member_manage") ||
        this.authMain.find((af) => af && af.resCode == "department_manage") ||
        this.authMain.find((af) => af && af.resCode == "tenant_manage") ||
        this.authMain.find((af) => af && af.resCode == "role_rights_management")
      );
    },
  },
  created() {
    getPlatformList({})
      .then(({ resdata }) => {
        this.developmentPlatform = resdata;
      })
      .catch((err) => {});
    listWithApps({ id: "0" })
      .then(({ res }) => {
        console.log("🚀 ~ .then ~ res:", res)
        this.appData = res.data;
      })
      .catch((err) => {});
  },
  mounted() {
    let heartbeat = setInterval(() => {
      let data = {
        token: sessionStorage.getItem("Authorization"),
      };
      if (sessionStorage.getItem("Authorization")) {
        //每20分钟调用一次心跳的接口
        sendHeartbeat(data).catch((err) => {
          clearInterval(heartbeat);
        });
      }
    }, 1000 * 60 * 10);
    window.addEventListener("scroll", this.pageScroll);
    this.tabsData.forEach((item) => {
      if (item.url.includes(this.$route.name)) {
        this.tabIndex = item.key;
      }
    });
    if (this.$route.name == "appDetails") {
      // 方案与案例
      this.tabIndex = 1;
    } else if (
      ["bigData", "otPage", "digitalTwins"].includes(this.$route.name)
    ) {
      this.tabIndex = 1;
    }
    console.log("tab", this.tabIndex, this.$route);
    this.clientId = sessionStorage.getItem("ucenter_client_id") || "";

    this.$bus.$on("logOut", (data) => {
      console.log("logout ----401推出啦接收到了")
      this.exit();
    });
    if (this.userInfo.realName) {
      this.getTenantList();
    }

    if (
      sessionStorage.getItem("ucenter_Authorization") ||
      localStorage.getItem("loginToken")
    ) {
      this.getUserInfo();
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.pageScroll);
  },
  watch: {
    $route(to, from) {
      if (this.userInfo.realName) {
        this.getTenantList();
      }
      this.tabIndex = -1;
      this.tabsData.forEach((item) => {
        if (item.url == to.fullPath) {
          this.tabIndex = item.key;
        }
      });
      if (this.$route.name == "appDetails") {
        this.tabIndex = 1;
      }
      if (
        this.$route.name == "bigData" ||
        this.$route.name == "otPage" ||
        this.$route.name == "digitalTwins" ||
        this.$route.name == "componentCenter"
      ) {
        this.tabIndex = 1;
      }
      if (this.$route.name == "updateDate") {
        this.tabIndex = 3;
      }
      if (this.$route.name == "helpCenter") {
        this.tabIndex = 4;
      }
    },
    // "$store.state.userInfo.userId": {
    //   handler:function(newVal,oldVal){
    //     if (this.$store.state?.userInfo?.userId) {
    //       this.getTenantList()
    //     }
    // }
    // }
  },
  methods: {
    getUserInfo() {
      getInfo().then(({ res }) => {
        debugger
        if (res.code == 0) {
          this.$store.commit("setUserInfo", { ...res.data });
          sessionStorage.setItem(
            "userInfo",
            JSON.stringify({ ...res.data, id: res.data.userId })
          );
          this.getUserDeptList();
        } else if (res.code == 401) {
          this.exit();
        }
      });
    },
    getUserDeptList() {
      getUserDept({}).then(async ({ res }) => {
        console.log("🚀 ~ getUserDept ~ res:", res)
        if (res.code == 200) {
          this.userDeptList = res.data.map((item) => {
            return {
              ...item,
              active: false,
            };
          });
          if (this.userDeptList.length == 1) {
            this.userDeptList[0].active = true;
            this.$store.commit(
              "setUserDeptName",
              this.userDeptList[0].clientName
            );
            sessionStorage.setItem(
              "ucenter_client_id",
              this.userDeptList[0].clientId
            );
            sessionStorage.setItem(
              "ucenter_client_name",
              this.userDeptList[0].name
            );
            if (this.userDeptList[0].isDevelop == 1) {
              sessionStorage.setItem(
                "ucenter_develop",
                this.$store.state.userInfo.isDevelop
              );
            } else {
              sessionStorage.setItem("ucenter_develop", 0);
            }
            sessionStorage.setItem(
              "ucenter_isMainTenant",
              this.userDeptList[0].isMainTenant
            );
          } else {
            const clientName = res.data.filter((item) => {
              return item.clientId == localStorage.getItem("ucenter_client_id");
            });
            if (clientName.length) {
              // this.onCommand(clientName[0], false)
              const data = clientName[0];
              sessionStorage.setItem("ucenter_client_id", data.clientId);
              localStorage.setItem("ucenter_client_id", data.clientId);
              localStorage.setItem("ucenter_client_name", data.clientName);
              sessionStorage.setItem("ucenter_client_name", data.clientName);
              sessionStorage.setItem("ucenter_isMainTenant", data.isMainTenant);
              
              sessionStorage.setItem(
                  "ucenter_develop",
                  data.isDevelop || 0
                );
              this.$store.commit("setUserDeptName", data.clientName);
              const _auth = await getAuthInfo();
              this.$store.commit("setAuth", _auth.res.data);
            }
          }
        } else if (res.code == 401) {
          this.exit();
        }
      });
    },
    toLogin() {
      this.$router.push("/login");
    },
    linktTo() {
      this.$router.push("/system");
    },
    centerTo() {
      this.$refs.usCenter.init();
    },
    getTenantList() {
      getUserDept({})
        .then(({ res }) => {
          this.userDeptList = res.data;
          this.$forceUpdate();
        })
        .catch((err) => {});
    },
    onUpdate(item) {
      upgrade({ id: item.clientId })
        .then(({ res }) => {
          // 重新刷新下list
          item.isDevelop = !item.isDevelop;
          this.$message.success("升级成功");
          this.getTenantList();
        })
        .catch((err) => {});
    },
    pageScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    clickItem(tab, index) {
      this.tabIndex = tab.key;
      if (
        this.$route.name == "applicationCenter" &&
        tab.url == "/applicationCenter"
      ) {
        // 因为是iframe当前要
        window.location.reload();
        return;
      }
      this.goUrl(tab.url);
    },
    //退出
    exit() {
      this.$store.commit("setUserInfo", {
        username: "",
        realName: "",
        isDevelop: 0,
        mobile: "",
        lastLoginTime: "",
        userId: "",
        userName: "",
        loginCount: 0,
      });
      this.$store.commit("setUserDeptName", "");
      this.$store.commit("setClientId", "");
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("ucenter_Authorization");
      sessionStorage.removeItem("ucenter_client_id");
      sessionStorage.clear();
      // localStorage.clear();
      localStorage.setItem("ucenter_Authorization", '');
      localStorage.setItem("loginToken", '');
      this.clearCookie();
      this.$message.success("退出成功");
      if (this.$route.query.url) {
        let path = window.location.search;
        let url = path.slice(window.location.search.indexOf("https"));
        this.goUrl("login?url=" + decodeURIComponent(url));
        return;
      }
      this.goUrl("login");
      // window.location.reload()
    },
    //后台
    backstage(data) {
      if (this.userInfo && data == "1") {
        window.location.href = "/it/engineering/engineer/engineering";
        this.goUrl("login");
      } else if (this.userInfo && data == "0") {
        this.$router.push("/applicationStation");
      }
    },
    //清空cookie
    clearCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下的,例如：m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString(); //清除当前域名下的，例如 .m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=kevis.com;expires=" +
            new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .kevis.com
        }
      }
      console.log("已清除");
    },

    goUrl(code) {
      let url;
      let codeUrl = {
        IT: "/itPage",
        OT: "/otPage",
        BD: "/bigData",
        DT: "/digitalTwins",
      };
      url = codeUrl[code] || code;
      this.$router.push({
        path: url,
      });
      this.hoverTab = -1;
    },
    goAppDetails(item) {
      this.hoverTab = -1;
      this.$router.push({
        path: "/appDetails",
        query: {
          id: item.id,
        },
      });
      console.log("router", this.$route, item);
      // if (this.$route.name !==  'applicationCenter') {
      //   this.$router.push({
      //     path: "/applicationCenter",
      //   });
      // } else {

      // }
    },
    mouseenter(tab) {
      this.hoverTab = tab.key;
    },
    mouseleave() {
      this.hoverTab = -1;
    },
    async onCommand(data, reload = true) {
      if (data == "add") {
        this.$router.push({
          path: "/login",
          query: {
            type: "addCompany",
          },
        });
      } else {
        sessionStorage.setItem("ucenter_client_id", data.clientId);
        localStorage.setItem("ucenter_client_id", data.clientId);
        localStorage.setItem("ucenter_client_name", data.clientName);
        sessionStorage.setItem("ucenter_client_name", data.clientName);
        sessionStorage.setItem("ucenter_isMainTenant", data.isMainTenant);
        if (data.isDevelop == 1) {
          sessionStorage.setItem(
            "ucenter_develop",
            this.$store.state.userInfo.isDevelop
          );
        } else {
          sessionStorage.setItem("ucenter_develop", 0);
        }
        this.$store.commit("setUserDeptName", data.clientName);
        const _auth = await getAuthInfo();
        this.$store.commit("setAuth", _auth.res.data);
        if (reload) {
          window.location.reload();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  padding: 0;
  .el-dropdown-menu__item {
    .title {
      color: #262626;
    }
  }
  .el-dropdown-menu__item--divided {
    margin: 0;
  }
  .el-dropdown-menu__item--divided:before {
    height: 0;
  }
}
.flex-wrap {
  flex-wrap: wrap;
}
.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  .left {
    display: flex;
    align-items: center;
    height: 64px;
    justify-content: space-between;
    padding: 0 20px;
    .imgsize {
      width: 170px;
      /* margin: 0 0 0 5%; */
    }
    .title {
      font-size: 18px;
      color: #262626;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tabDiv {
        display: flex;
        .acitve {
          font-weight: bold;
          color: #0b66f5 !important;
        }
        .item {
          font-size: 14px;
          color: #262626;
          line-height: 36px;
          cursor: pointer;
          /* min-width: 106px; */
          margin-right: 60px;
          &:last-child {
            margin-right: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .item:hover {
          font-weight: bold;
          color: #0b66f5;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      min-width: 170px;
      justify-content: flex-end;
      .user {
        font-size: 12px;
        .imgSize {
          min-width: 32px;
          height: 32px;
          flex-shrink: 0;
          border-radius: 50%;
        }
      }
      .btn {
        width: 105px;
        height: 36px;
        background: #0b66f5;
        border-radius: 3px;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        line-height: 36px;
        cursor: pointer;
      }
    }
  }
  .hoverContent {
    display: flex;
    width: 100%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    background: #fff;
    .imgSize {
      width: 317px;
      height: 378px;
      background: rgba(250, 252, 255, 0.13);
      backdrop-filter: blur(0px);
    }

    .hover-right {
      display: flex;
      justify-content: center;
      width: 100%;
      .item:hover {
        border: 1px solid #0b66f5;
      }
      .item {
        margin-top: 40px;
        margin-left: 32px;
        max-width: 480px;
        height: 128px;
        background: #f6f9fe;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 30px 24px;
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #262626;
          line-height: 24px;
          // color: #0b66f5;
          cursor: pointer;
        }
        .title:hover {
          color: #0b66f5;
        }
        .text {
          margin-top: 5px;
          font-size: 14px;
          color: #595959;
          line-height: 20px;
        }
      }
      .item_use {
        margin: 40px;
        .title {
          font-size: 14px;
          font-weight: bold;
          color: #262626;
          line-height: 21px;
        }
        .title:hover {
          color: #0b66f5;
        }
        .text {
          cursor: pointer;
          margin-top: 20px;
          font-size: 14px;
          color: #595959;
          line-height: 21px;
        }
        .text:hover {
          color: #0b66f5;
        }
      }
    }
  }
}
.scroll-bg {
  background: #fff;
  transition: all 0.001s linear;
}
.default-bg {
  background: rgba(255, 255, 255, 0.4);
  transition: all 0.001s linear;
  /* background: #fff; */
  /* box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1) !important; */
}
.role-wrapper {
  margin-right: 34px;
  ::v-deep .el-dropdown-menu__item {
    width: 260px;
  }
  .text-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .userIcon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .name {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #262626;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 15px;
      min-width: 65px;
    }
  }
}
</style>
<style lang="scss">
.role-item-wrapper {
  width: 260px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  margin-top: 30px !important;
  .drop-top-wrapper {
    max-height: 400px;
    overflow-y: auto;
  }
  .el-dropdown-menu__item,
  .el-menu-item {
    padding: 12px 16px;
    border-top: 1px dashed #f5f5f5 !important;
    &:first-child {
      border-top: 0;
    }
  }
  .role-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .item-left {
      display: flex;
      align-items: center;
      .icon-left {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
      .role-name {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #262626;
        position: relative;
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .person-tip {
          position: absolute;
          top: -5px;
          right: -40px;
          font-size: 10px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #8c8c8c;
          width: 42px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #f5f5f5;
          border-radius: 8px;
        }
      }
    }
    .item-right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
      .right-text {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #0b66f5;
      }
    }
  }
  .role-item-add {
    display: flex;
    align-items: center;
    justify-content: center;
    .add-image {
      width: 16px;
      height: 16px;
    }
    .text {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #262626;
      margin-left: 8px;
    }
  }
}
.el-dropdown-link {
  display: flex;
  align-items: center;
  /* margin-right: 30px; */
  margin-left: 10px;
}
.work-wrapper {
  width: 105px;
  padding: 5px 0 !important;
}
</style>
