<template>
  <div v-if="!isPCheadshow" class="bg">
    <div class="content">
      <div class="left">
        <div class="left-left">
          <img class="logo" src="@/assets/index/logo-w.png" alt="" />
          <div style="margin-top: 0">
            <img src="@/assets/index/foot-1.png" alt="" />
            <span>股票代码：300532</span>
          </div>
          <div>
            <img src="@/assets/index/foot-2.png" alt="" />
            <span>股票简称：今天国际</span>
          </div>
          <div>
            <img src="@/assets/index/foot-3.png" alt="" />
            <span>0755-25161222</span>
          </div>
          <div>
            <img src="@/assets/index/foot-4.png" alt="" />
            <span>周一至周五 09:00-18:00</span>
          </div>
        </div>
        <div class="left-right">
          <div style="margin-right: 50px">
            <span>关注钉钉企业主页</span>
            <img src="@/assets/index/code1.png" alt="" />
          </div>
          <div>
            <span>关注微信公众号</span>
            <img src="@/assets/index/code2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="block">
          <div class="top">热门应用</div>
          <div class="word" @click="jump('/appDetails', { app_code: '', id: '' })">
            智慧物流协同管理系统
          </div>
          <div
            class="word"
            @click="jump('/appDetails', { app_code: 'ntiwms', id: '1720328220198252548' })"
          >
            仓库管理系统
          </div>
          <div class="word" @click="jump('/appDetails', { app_code: 'yms',  id:'1739841596054560771' })">
            数字月台
          </div>
          <div class="word" @click="jump('/appDetails', { app_code: 'piic',  id:'1803973643491864622' })">
            智能盘点
          </div>
          <div class="word" @click="jump('/appDetails', { app_code: 'device',  id:'1607684832454716506' })">
            设备云管
          </div>
        </div>

        <div class="block">
          <div class="top">开发平台</div>
          <div
            v-for="item in titleData"
            :key="item.id"
            class="word"
            @click="linkToPage(item)"
          >
            {{ item.develop_name }}
          </div>
        </div>
        <div class="block">
          <!-- <div class="title">最新功能</div>
          <div
            v-for="item in titleData"
            :key="item.id"
            class="text"
            @click="jump('/updateDate', { develop_code: item.develop_code })"
          >
            {{ item.develop_name }}开发平台
          </div> -->
        </div>
      </div>
    </div>
    <div class="copyright">
      深圳市今天国际物流技术股份有限公司 版权所有 粤ICP备12074812号 Copyright ©
      2000-{{ getYear() }} NTI. All Rights Reserved
    </div>
  </div>
</template>
<script>
import { getPlatformList } from "@/api/platformCenter";
export default {
  data() {
    return {
      titleData: [
        {
          develop_name: "可配置化软件开发平台",
          develop_code: "IT",
          id: "1604087211316346883",
        },
        {
          id: "1604087317247688707",
          develop_code: "OT",
          develop_name: "物联网数据采集开发平台",
        },
        {
          id: "1604087384650153986",
          develop_code: "BD",
          develop_name: "大数据开发平台",
        },
        {
          id: "1604087384650153989",
          develop_code: "DT",
          develop_name: "数字孪生开发平台",
        },
        {
          develop_name: "今天云开发者平台",
          develop_code: "Developer",
          id: "1604087211316399999",
        },
      ],
    };
  },
  created() {
    // getPlatformList({})
    //   .then(({ resdata }) => {
    //     this.titleData = resdata;
    //   })
    //   .catch((err) => {});
  },
  computed: {
    isPCheadshow() {
      let isMobile;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
      ) {
        isMobile = true;
      } else {
        isMobile = false;
      }
      return (
        (this.$route.name == "system" && !isMobile) ||
        this.$route.name == "login" ||
        this.$route.name == "applicationStation" ||
        this.$route.name == "componentCenter" ||
        this.$route.name == "configApplication" ||
        window.location.href.includes("/configApplication") ||
        window.location.href.includes("/appstore")
      );
    },
  },
  methods: {
    linkToPage(item) {
      let userInfo = sessionStorage.getItem("userInfo");
      const userId = this.$store.state.userInfo.id;
      let key = item.develop_code;
      if (userInfo) {
        switch (key) {
          case "IT":
            window.location.href = "/it/engineering/engineer/engineering";
            break;

          case "OT":
            const url = "https://cloud.nti56.com/ot";
            window.open(
              `https://trial.nti56.com/cloudnest/OT/link?appCode=OT&returnUrl=${url}&clientId=${sessionStorage.getItem(
                "ucenter_client_id"
              )}&token=${sessionStorage.getItem("loginToken")}&ddtab=true`
            );
            break;

          case "BD":
            const token = sessionStorage.getItem("ucenter_Authorization") || "";
            // 测试环境 http://10.1.21.34:89/login
            window.open(
              "https://cloud.nti56.com/etl/login?token=" + token + '&ddtab=true',
              "_blank"
            );
            break;

          case "DT":
            const tokenDT = sessionStorage.getItem("loginToken") || "";
            window.open(
              `https://cloud.nti56.com/dtts/ddExemption?token=${tokenDT}&clientId=${sessionStorage.getItem('ucenter_client_id')}&userId=${userId}&ddtab=true`,
              "_blank"
            );
            break;
          case "Developer":
            // 如果是今天云开发平台，直接跳转到对应的地址 process.env.VUE_APP_CLIENTID
            window.open(
              `${
                process.env.VUE_APP_DEVELOPER_URL
              }/developer/?token=${sessionStorage.getItem("loginToken")}&ddtab=true`,
              "_blank"
            );
            // window.open(`http://localhost:8765/developer/?token=eyJhbGciOiJIUzUxMiJ9.eyJ1c2VySW5mbyI6IntcImFwcGNvZGVcIjpcImRldmVsb3Blcl9wbGF0XCIsXCJjbGllbnRJZFwiOlwiMTAxMDIwMzA0MDUwNjA3MDgwOVwiLFwiY3JlYXRlQnlcIjoxNzI0MzgwODAwMjA2MTM5OTgyLFwiY3JlYXRlVGltZVwiOjE3MDAxMTk2NjEwMDAsXCJjdXJyZW50XCI6MSxcImRlbGV0ZWRcIjowLFwiZGVwdG5hbWVcIjpcIjEyMTEyMTIsUE1P5bmz5Y-wLOS7iuWkqeS6keW8gOWPkeW5s-WPsCznp5_miLcwODAxXCIsXCJlbXBDb250YWN0XCI6XCIxODM4OTc5NTg4N1wiLFwiZW1wTmFtZVwiOlwi6ZmI5YmR5LyfXCIsXCJmaW5nZXJwcmludEtleVwiOlwiXCIsXCJoZWFkZXJBcHBjb2RlXCI6XCJkZXZlbG9wZXJfcGxhdFwiLFwiaWRcIjoxNTY4MjM1MTg2NDc5NjA3ODA5LFwiaXNBZG1pblwiOlwiMFwiLFwiaXNEb3VibGVGYWN0b3JcIjowLFwiaXNSZWdcIjoxLFwibGFzdExvZ2luVGltZVwiOjE3MjQwNTc4MDgwMDAsXCJsb2dpbkNvdW50XCI6MTg4NSxcIm1vZGlmeUJ5XCI6MTc2NzQ2MzgzNTc4ODA2MjcyMCxcIm1vZGlmeVRpbWVcIjoxNzI0MDU3ODA4MDAwLFwib3Blbk11bHRpTG9naW5cIjp0cnVlLFwib3B0aW1pemVDb3VudFNxbFwiOnRydWUsXCJvcHRpbWl6ZUpvaW5PZkNvdW50U3FsXCI6dHJ1ZSxcIm9yZGVyc1wiOltdLFwicGFnZXNcIjowLFwicGFzc3dvcmRcIjpcImZlNWJjMjAyZTU0N2M0M2Q0NzkwM2JjNWJlNDljNGFiXCIsXCJzZWFyY2hDb3VudFwiOnRydWUsXCJzaXplXCI6MTAsXCJzdXBlckFkbWluXCI6ZmFsc2UsXCJ0b3RhbFwiOjAsXCJ1c2VyQ29kZVwiOlwiMTU2ODIzNTE4NjQ3OTYwNzgwOVwiLFwidXNlckhlYWRQb3J0cmFpdFwiOlwiXCIsXCJ1c2VyTmFtZVwiOlwiZ2Vud2FpMTIzXCIsXCJ1c2VyU3RhdHVzXCI6MSxcInVzZXJUeXBlXCI6MixcInZlcmlmaWNhdGlvbk1vZGVcIjowfSIsImV4cCI6MTcyNDIwNjkwOSwiaWF0IjoxNzI0MTIwNTA5fQ.q6HSFnxtNEEKpI6Ek67TPt1BUycUETyPLnOS0sNmNL1yEVqXpw_PmTnKCOw5gYJ_wlHdcoOWjo2c9AOgyWWdtA`,'_blank');
            break;

          default:
            break;
        }
        return;
      }
      this.goUrl("login");
    },
    goUrl(code) {
      let url;
      let codeUrl = {
        IT: "/itPage",
        OT: "/otPage",
        BD: "/bigData",
        DT: "/digitalTwins",
      };
      url = codeUrl[code] || code;
      this.$router.push({
        path: url,
      });
      this.hoverTab = -1;
    },
    getYear() {
      let time = new Date();
      return time.getFullYear();
    },
    jump(path, query = {}) {
      if (!query.app_code) {
        return false;
      }
      this.$router.push({ path, query });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: #20314d;
  padding: 77px 0 25px;
  color: #fff;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  .left {
    flex: 0 0 50%;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    .left-left {
      .logo {
        width: 140px;
        height: 24px;
        margin-bottom: 28px;
      }
      & > div {
        display: flex;
        align-items: center;
        margin-top: 12px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          font-size: 16px;
        }
      }
    }
    .left-right {
      display: flex;
      margin-right: 47px;
      padding-top: 18px;
      span {
        display: block;
        text-align: center;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.87);
        margin-bottom: 15px;
      }
      img {
        width: 122px;
        height: 122px;
      }
    }
  }
  .right {
    flex: 0 0 50%;
    margin-left: 47px;
    display: flex;
    .block {
      flex: 1;
      // display: flex;
      .top {
        font-size: 18px;
        margin-bottom: 8px;
      }
      .word {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        margin-top: 16px;
        cursor: pointer;
        &:hover {
          /* opacity: 0.8; */
        }
      }
    }
  }
}
.copyright {
  margin-top: 60px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 16px;
}
</style>
